import React from "react";
import styles from "./Link.module.scss";

/**
 * A styled Link component that opens links in a new tab
 *
 * @param {object} props Component props
 * @param {object} props.className Additional class name(s) for styling the component
 * @param {object} props.href The link's URL
 * @param {object} props.onClick onClick handler
 */
export const Link = ({ children, className, href, onClick }) => (
  <a
    className={`${styles.link} ${className || ""}`}
    onClick={onClick}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
);
