import React from "react";
import { getNewsfeed } from "../../services/api";
import { Loader } from "../common/Loader";
import { AddNewPost } from "./AddNewPost";
import styles from "./Newsfeed.module.scss";
import { Post } from "./Post";

/**
 * Loads the newsfeed from the server and displays it
 */
export const Newsfeed = () => {
  return (
    <div className={styles.container} data-testid="newsfeed">
      <Loader load={getNewsfeed} loadingMessage="Loading the newsfeed...">
        {({ newsfeed }) => (
          <div className={styles.content} data-testid="newsfeedContent">
            <div className={styles.item}>
              <AddNewPost />
            </div>
            {newsfeed.map((post, idx) => (
              <div className={styles.item} key={`${idx}-${post.photo.id}`}>
                <Post {...post} />
              </div>
            ))}
          </div>
        )}
      </Loader>
    </div>
  );
};
