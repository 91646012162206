import React, { useState } from "react";
import { Link } from "../common/Link";
import { VerificationWidget } from "../verification/VerificationWidget";
import styles from "./Post.module.scss";

const Header = ({ author, timeAgo, isArticle }) => (
  <header className={styles.header} data-testid="postHeader">
    <img className={styles.header_avatar} src={author && author.photo} alt="author-avatar" />
    <div className={styles.header_title}>
      <span className={styles.header_title_author}>{author && author.name}</span>
      {isArticle ? " shared a post" : null}
    </div>
    <div className={styles.header_timeAgo}>{timeAgo} ago</div>
  </header>
);

const Photo = ({ id, url, verification, relatedEvents }) => {
  // Reduce jumping of the image by setting it to 60% height until it is loading
  const [paddingBottom, setPaddingBottom] = useState("60%");
  function handleLoad() {
    setPaddingBottom("0");
  }
  return (
    <div className={styles.photo}>
      <img
        style={{ paddingBottom }}
        className={styles.photo_image}
        src={url}
        alt="Post"
        onLoad={handleLoad}
        data-testid="postImage"
      />
      {verification ? (
        <VerificationWidget {...verification} relatedEvents={relatedEvents} photoId={id} />
      ) : null}
    </div>
  );
};

const ArticleInfo = ({ title, publisher, publisherURL }) => (
  <div className={styles.articleInfo} data-testid="postArticleInfo">
    {publisher ? (
      <Link className={styles.articleInfo_publisher} href={publisherURL}>
        {publisher}
      </Link>
    ) : null}
    {title ? <div className={styles.articleInfo_title}>{title}</div> : null}
  </div>
);

const Footer = ({ likes, comments }) => {
  return (
    <div>
      <footer className={styles.footer}>
        <menu className={styles.footer_actionMenu} data-testid="postActionMenu">
          <button className={styles.footer_actionMenu_button}>
            {`${likes} ${likes !== 1 ? "Likes" : "Like"}`}
          </button>
          <button className={styles.footer_actionMenu_button}>
            {`${comments} ${comments !== 1 ? "Comments" : "Comment"}`}
          </button>
          <button className={styles.footer_actionMenu_button}>Share</button>
        </menu>
      </footer>
    </div>
  );
};

/**
 * Displays a post in the newsfeed
 *
 * When the photo has verification information, it renders the VerificationWidget
 *
 * @param {string} author The name of the post's author
 * @param {string} props.timeAgo When the post was posted
 * @param {object} props.photo The photo associated with the post
 * @param {object} props.article The article associated with the post
 * @param {string} text User text associated with the post
 */
export const Post = ({ author, timeAgo, photo, article, text, likes, comments, relatedEvents }) => (
  <article data-testid="post">
    <Header author={author} timeAgo={timeAgo} isArticle={!!article} />
    {text ? (
      <div className={styles.text} data-testid="postText">
        {text}
      </div>
    ) : null}
    <Photo {...photo} relatedEvents={relatedEvents} />
    {article ? <ArticleInfo {...article} /> : null}
    <Footer likes={likes} comments={comments} />
  </article>
);
