/**
 * Sets the position and size of a line element so that it connects the first and last anchor element
 *
 * @param {HTMLElement} lineEl The line element
 * @param {HTMLElement} containerEl The container element containing the line and anchor elements
 * @param {string} anchorsClassName The class name of the anchor elements
 */
export function layoutLineElement(lineEl, containerEl, anchorsClassName) {
  const anchorEls = containerEl.getElementsByClassName(anchorsClassName);
  if (anchorEls.length < 2) {
    return;
  }

  const topAnchorEl = anchorEls[0];
  const bottomAnchorEl = anchorEls[anchorEls.length - 1];

  const containerRect = containerEl.getBoundingClientRect();
  const topAnchorRect = topAnchorEl.getBoundingClientRect();
  const bottomAnchorRect = bottomAnchorEl.getBoundingClientRect();

  lineEl.style.left = topAnchorRect.left - containerRect.left + topAnchorRect.width / 2 + "px";
  lineEl.style.top = topAnchorRect.top - containerRect.top + "px";

  lineEl.style.width = (bottomAnchorRect.left - topAnchorRect.left) / 5 + "px";

  lineEl.style.height =
    bottomAnchorRect.top - topAnchorRect.top + bottomAnchorRect.height / 2.5 + "px";
}
