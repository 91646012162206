import React, { Component } from "react";
import { ReactComponent as ImageIcon } from "../../icons/photo.svg";
import { ReactComponent as UserPhotoIcon } from "../../icons/user-icon.svg";
import styles from "./AddNewPost.module.scss";

/**
 * Static component for adding a new post to the newsfeed
 */
export class AddNewPost extends Component {
  state = {};
  render() {
    return (
      <div data-testid="addNewPost">
        <form className={styles.container}>
          <UserPhotoIcon className={styles.avatar} />
          <input
            className={styles.textBox}
            type="text"
            placeholder="What do you want to talk about?"
          />
          <ImageIcon className={styles.imageIcon} />
        </form>
      </div>
    );
  }
}
