import React, { Component } from "react";
import styles from "./Loader.module.scss";

/**
 * Loads data from the server and displays it,
 * including error handling and retry
 */
export class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null,
      error: null
    };
  }
  componentDidMount() {
    this.load();
  }
  render() {
    const { isLoading, data, error } = this.state;
    return (
      <div data-testid="loader">
        {isLoading ? this.renderLoading() : null}
        {error ? this.renderError() : null}
        {data ? this.props.children(data) : null}
      </div>
    );
  }
  renderLoading() {
    return (
      <div className={styles.loading} data-testid="loaderLoading">
        {this.props.loadingMessage}
      </div>
    );
  }
  renderError() {
    return (
      <div className={styles.error}>
        <div data-testid="loaderError">{this.renderErrorMessage()}</div>
        <button className={styles.retry} onClick={this.load} data-testid="loaderRetry">
          Retry
        </button>
      </div>
    );
  }
  renderErrorMessage() {
    const { error } = this.state;
    if (!error.response) {
      return "Slow or no internet connection. Please check your internet settings and try again.";
    }
    return "There was an issue loading information from the blockchain. Please try again.";
  }
  load = async () => {
    this.setState({ isLoading: true, error: null });
    try {
      const data = await this.props.load();
      this.setState({ data, isLoading: false });
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  };
}
