import React, { useState } from "react";
import { ReactComponent as CalendarDarkIcon } from "../../icons/calendar_dark_icon.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar_icon.svg";
import { ReactComponent as CarrotDownIcon } from "../../icons/carrot_down.svg";
import { ReactComponent as CarrotUpIcon } from "../../icons/carrot_up.svg";
import { ReactComponent as NationalNewsLogoIcon } from "../../icons/national_news-v2.svg";
import { ReactComponent as NppLogoIcon } from "../../icons/npp_logo.svg";
import { ReactComponent as LocationPinIcon } from "../../icons/pin_icon.svg";
import relativeTimeCalculator from "../common/ElapsedTime";
import { InfoBanner } from "./InfoBanner";
import { PhotoDetails } from "./PhotoDetails";
import styles from "./VerificationWidget.module.scss";

function GetSignalText({ source, origin, originalCaption }) {
  let current = new Date();
  let originalSourceDateTime = origin.dateTime.date + " " + origin.dateTime.time;
  originalSourceDateTime = new Date(originalSourceDateTime).getTime();
  const sixMonthBeforeNow = new Date(current).setMonth(current.getMonth() - 6);

  return (
    <div>
      <div
        className={styles.headContent_text}
        title={originalCaption.text}
        data-testid="verificationWidgetCaption"
      >
        {originalCaption && originalCaption.text}
      </div>
      <div className={`${styles.headContent_container} ${styles.headContent_container_text}`}>
        <div className={styles.headContent_infoText}>{source.name}</div>
        <div className={styles.headContent_infoText} data-testid="verificationWidgetLocation">
          <LocationPinIcon className={styles.headContent_infoIcon} />
          Taken in {origin && origin.location && origin.location.name}
        </div>
        {originalSourceDateTime < sixMonthBeforeNow ? (
          <div className={`${styles.headContent_text} ${styles.headContent_relativeTimeContainer}`}>
            <CalendarDarkIcon className={styles.headContent_infoDarkIcon} />
            {relativeTimeCalculator(current, new Date(originalSourceDateTime))}
          </div>
        ) : (
          <div className={styles.headContent_infoText}>
            <CalendarIcon className={styles.headContent_infoIcon} />
            {relativeTimeCalculator(current, new Date(originalSourceDateTime))}
          </div>
        )}
      </div>
    </div>
  );
}

const Collapsed = props => {
  const text = GetSignalText(props);
  return (
    <div>
      <div className={`${styles.innerContainer} ${styles.innerContainer__collapsed}`}>
        <div className={styles.head} onClick={props.onClick} data-testid="verificationWidget">
          <NppLogoIcon className={styles.head_logoIcon} />
          <div
            className={`${styles.head_text} ${styles.head_text_fullText}`}
            data-testid="verificationWidgetCollapsedTitle1"
          >
            Check for yourself: What does this photo show?
          </div>
          <div className={`${styles.head_text} ${styles.head_text_shortText}`}>
            What does this photo show?
          </div>
          <div className={styles.head_textCarrot}>More</div>
          <CarrotDownIcon className={styles.head_textCarrot_carrotIcon} />
        </div>
        <div className={styles.headContent}>
          <NationalNewsLogoIcon className={styles.head_logoIcon} />
          <div className={styles.headContent_text}>{text}</div>
        </div>
      </div>
    </div>
  );
};

const Expanded = props => {
  return (
    <div className={`${styles.innerContainer} ${styles.innerContainer__expanded}`}>
      <div data-testid="verificationWidget">
        <div
          className={`${styles.head} ${styles.head__expanded}`}
          onClick={props.onClick}
          data-testid="verificationWidgetHeader"
        >
          <NppLogoIcon className={styles.head_logoIcon} />
          <div
            className={`${styles.head_text} ${styles.head_text_fullText}`}
            data-testid="verificationWidgetCollapsedTitle2"
          >
            Check for yourself: What does this photo show?
          </div>
          <div className={`${styles.head_text} ${styles.head_text_shortText}`}>
            What does this photo show?
          </div>
          <div className={styles.head_textCarrot}>Less</div>
          <CarrotUpIcon className={styles.head_textCarrot_carrotIcon} />
        </div>
        <PhotoDetails {...props} />
        <InfoBanner />
      </div>
    </div>
  );
};

/**
 * Displays the signal at the bottom of photos and handles its collapse / expansion
 *
 * When collapsed, it briefly shows origin information about the photo
 * When expanded, it renders the PhotoDetails and InfoBanner components
 *
 * @param {object} props Component props
 * @param {object} props.source The source of the photo
 * @param {object} props.origin The origin of the photo
 * @param {object} props.originalCaption The original (initial) photo caption
 * @param {array} props.republishedBy List of publishers that also published the photo
 * @param {array} props.publishHistory List of publish history events
 */
export const VerificationWidget = props => {
  const [isExpanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!isExpanded);
  }

  const Content = isExpanded ? Expanded : Collapsed;
  return (
    <div className={styles.outerContainer}>
      <div className={styles.gradient} />
      <Content {...props} onClick={toggleExpanded} />
    </div>
  );
};
