import React from "react";
import { ReactComponent as LinkIcon } from "../../icons/link_icon.svg";
import { ReactComponent as NppLogoIcon } from "../../icons/npp_logo.svg";
import { Link } from "../common/Link";
import styles from "./InfoBanner.module.scss";

const mobileImgUrl = "/how-it-works/how-it-works-8-26-mobile.pdf";
const desktopImgUrl = "/how-it-works/how-it-works-8-26-desktop.pdf";
const ResponsiveImage = () => {
  return window.screen.width < 1024 ? mobileImgUrl : desktopImgUrl;
};

/**
 * The "Published by..." banner is displayed at the bottom of the verification widget
 */
export const InfoBanner = () => {
  return (
    <div className={styles.container} data-testid="infoBanner">
      <div className={styles.container_wrap}>
        <NppLogoIcon className={styles.authenticatedBy_logo} />
        Photo details authenticated by the News Provenance Project
      </div>
      <Link className={`${styles.howItWorks} ${styles.howItWorks_link}`} href={ResponsiveImage()}>
        <LinkIcon className={styles.authenticatedBy_infoIcon} />
        How this network of news organizations tracks photo origins
      </Link>
    </div>
  );
};
