import React, { useEffect, useRef, useState } from "react";
import { layoutLineElement } from "../../utils/layout-line";
import { Link } from "../common/Link";
import { ChangeDescription } from "./ChangeDescription";
import styles from "./PhotoHistory.module.scss";

const DateTime = ({ date, shortDate, time, timezone }) => (
  <time className={styles.event_dateTime} title={`${date} ${time} ${timezone}`}>
    {shortDate}
  </time>
);

export const Event = ({ children, organization, dateTime }) => (
  <div className={styles.event} data-testid="photoHistoryEvent">
    <div className={styles.event_logo + " lineAnchor"}>
      <img src={organization.logoUrl} alt={`${organization.name} logo`} />
    </div>
    <div className={styles.event_titleDate}>
      <div className={styles.event_title}>{children}</div>
      <DateTime {...dateTime} />
    </div>
  </div>
);

const PhotoTakenRow = ({ source, origin }) => (
  <div className={styles.row}>
    <Event organization={source} dateTime={origin.dateTime}>
      Photo taken by {origin.credit} for {source.name}
    </Event>
  </div>
);

const PublishRow = ({ event, isRepublish, initialEvent, onTogglePopup }) => (
  <div className={styles.row}>
    <Event {...event}>
      {isRepublish ? "Republished by " : "Published by "}
      {event.organization.name} in <Link href={event.url}>{event.articleTitle}</Link>
    </Event>
    {isRepublish && event.photoCaption && event.photoCaption !== initialEvent.photoCaption ? (
      <div className={styles.change}>
        <ChangeDescription
          newEvent={event}
          initialEvent={initialEvent}
          onTogglePopup={onTogglePopup}
        />
      </div>
    ) : null}
  </div>
);

/**
 * Displays the photo capture and publishing history
 *
 * The photo-capture event is derived from the source and origin fields
 *
 * For each event in the publish history, a publish-event is rendered
 *
 * @param {object} props Component props
 * @param {object} props.source The source of the photo
 * @param {object} props.origin The origin of the photo
 * @param {array} props.publishHistory List of publish history events
 */
export const PhotoHistory = ({ source, origin, publishHistory }) => {
  const [showsOverlay, setShowsOverlay] = useState(false);
  const lineElementRef = useRef();

  function layoutLine() {
    const element = lineElementRef.current;
    if (element) {
      layoutLineElement(element, element.parentElement, "lineAnchor");
    }
  }

  useEffect(() => {
    layoutLine();
    window.addEventListener("resize", layoutLine);
    return () => {
      window.removeEventListener("resize", layoutLine);
    };
  }, []);

  return (
    <div className={styles.outerContainer} data-testid="photoHistory">
      <div className={styles.innerContainer}>
        <PhotoTakenRow source={source} origin={origin} />
        {publishHistory &&
          publishHistory.map((event, idx) => (
            <PublishRow
              key={idx}
              event={event}
              isRepublish={idx > 0}
              initialEvent={publishHistory[0]}
              onTogglePopup={popupShown => setShowsOverlay(popupShown)}
            />
          ))}
        {showsOverlay ? <div className={styles.overlay} data-testid="photoHistoryOverlay" /> : null}
      </div>
      <div className={styles.line} ref={lineElementRef} />
    </div>
  );
};
