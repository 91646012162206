import React, { Component, createRef } from "react";

/**
 * The Hoverable component reacts on mouseenter and mouseleave events on desktop clients to show pop-ups.
 * As soon as a touch input is recognized, it toggles whether a pop-up is shown using click-events.
 *
 * For more info on the event cascade on mobile:
 * https://www.quirksmode.org/blog/archives/2014/02/the_ios_event_c.html
 *
 * Note that the event handlers are directly attached to the DOM element,
 * as there were reliability issues with React's SyntheticEvent (https://reactjs.org/docs/events.html)
 *
 * @param {object} props Component props
 * @param {object} props.className Additional class name(s) for styling the component
 * @param {object} props.onChange Called with true when the element is hovered / tapped, called with false then the element is no longer hovered / tapped again
 */
export class Hoverable extends Component {
  elementRef = createRef();
  inputMode = "mouse";
  isActive = false;

  componentDidMount() {
    document.addEventListener("touchstart", this.handleTouchSomewhereElse);
  }
  componentWillUnmount() {
    document.removeEventListener("touchstart", this.handleTouchSomewhereElse);
  }
  render() {
    const events = {
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      onTouchStart: this.handleTouch,
      onClick: this.handleClick
    };
    return (
      <div
        {...events}
        ref={this.elementRef}
        className={this.props.className}
        data-testid="hoverable"
      >
        {this.props.children}
      </div>
    );
  }
  setActive(isActive) {
    this.isActive = isActive;
    this.props.onChange(this.isActive);
  }
  handleMouseEnter = () => {
    if (this.inputMode === "mouse") {
      this.setActive(true);
    }
  };
  handleMouseLeave = () => {
    if (this.inputMode === "mouse") {
      this.setActive(false);
    }
  };
  handleTouch = () => {
    this.inputMode = "touch";
  };
  handleClick = () => {
    if (this.inputMode === "touch") {
      this.setActive(!this.isActive);
    }
  };
  handleTouchSomewhereElse = event => {
    if (this.isActive && !this.elementRef.current.contains(event.target)) {
      this.setActive(false);
    }
  };
}
