import React from "react";
import { ReactComponent as LinkIcon } from "../../icons/link_icon_white.svg";
import styles from "./RelatedEvents.module.scss";

/*
 The "related events" component is displayed on the expanded widget > Photo Overview
*/

export function RelatedEvents({ relatedEvents }) {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.outerContainer_title}>More from this event</div>
      <div className={styles.outerContainer_wrap}>
        {relatedEvents
          ? relatedEvents.photos.map((photo, i) => (
              <div key={i}>
                <div className={styles.outerContainer_wrap_imageContainer}>
                  <div
                    style={{ backgroundImage: `url(${photo.image})` }}
                    className={styles.outerContainer_wrap_imageContainer_image}
                    alt="related-event"
                  >
                    <div className={styles.outerContainer_wrap_imageContainer_image_link}>
                      <LinkIcon className={styles.outerContainer_wrap_imageContainer_linkIcon} />
                      {photo.url}
                    </div>
                  </div>
                  <div className={styles.outerContainer_wrap_text}>{photo.title}</div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}
