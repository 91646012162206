import React, { useState } from "react";
import { VerificationWidget } from "../verification/VerificationWidget";
import styles from "./Article.module.scss";

/**
 * Displays an article
 */
export const Article = ({ title, publisher, dateTime, photo, verification }) => {
  const [paddingBottom, setPaddingBottom] = useState("60%");
  function handleLoad() {
    setPaddingBottom("0");
  }

  return (
    <article className={styles.content} data-testid="article">
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.authorDate}>
        <div>{publisher}</div>
        <div>{dateTime.shortDate}</div>
      </div>
      <div className={styles.photo}>
        <div>
          <img
            style={{ paddingBottom }}
            className={styles.photo_image}
            src={photo.url}
            alt=""
            onLoad={handleLoad}
          />
          {verification ? <VerificationWidget {...verification} photoId={photo.id} /> : null}
        </div>
        <div className={styles.photo_info}>
          <div className={styles.photo_caption}>{photo.caption}</div>
          <div className={styles.photo_source}>
            {photo.credit}/{photo.source.name}
          </div>
        </div>
      </div>
      <p className={styles.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Mauris commodo quis imperdiet massa. Ipsum suspendisse
        ultrices gravida dictum fusce ut placerat. Non pulvinar neque laoreet suspendisse interdum
        consectetur libero id faucibus. Tristique magna sit amet purus gravida quis blandit. Egestas
        quis ipsum suspendisse ultrices. Bibendum neque egestas congue quisque egestas. Proin libero
        nunc consequat interdum varius. Scelerisque eu ultrices vitae auctor. Elit ut aliquam purus
        sit amet. A diam maecenas sed enim ut. Magna etiam tempor orci eu lobortis elementum nibh.
        Enim lobortis scelerisque fermentum dui faucibus in. Dolor purus non enim praesent
        elementum.
      </p>
      <p className={styles.paragraph}>
        Dolor morbi non arcu risus quis varius quam. Neque ornare aenean euismod elementum nisi quis
        eleifend quam. Diam phasellus vestibulum lorem sed. Eros donec ac odio tempor. Et malesuada
        fames ac turpis. Eget mi proin sed libero enim sed faucibus turpis. Interdum posuere lorem
        ipsum dolor. Nisl purus in mollis nunc sed. Morbi tempus iaculis urna id volutpat lacus
        laoreet. Feugiat scelerisque varius morbi enim nunc faucibus. Gravida arcu ac tortor
        dignissim convallis aenean et tortor at. Aliquet risus feugiat in ante. Nunc faucibus a
        pellentesque sit amet porttitor eget dolor. Venenatis urna cursus eget nunc scelerisque
        viverra mauris. Eget nunc lobortis mattis aliquam faucibus purus in massa tempor.
      </p>
      <p className={styles.paragraph}>
        Malesuada nunc vel risus commodo viverra maecenas accumsan. Auctor neque vitae tempus quam
        pellentesque nec nam aliquam sem. Viverra vitae congue eu consequat ac felis. Convallis
        tellus id interdum velit laoreet. Mi in nulla posuere sollicitudin. Aliquam nulla facilisi
        cras fermentum odio eu feugiat pretium. Tempus quam pellentesque nec nam aliquam. Eleifend
        donec pretium vulputate sapien nec sagittis. Risus ultricies tristique nulla aliquet enim
        tortor. Ullamcorper malesuada proin libero nunc. Suspendisse faucibus interdum posuere lorem
        ipsum dolor sit amet consectetur. Aenean et tortor at risus viverra adipiscing at in. Sed
        velit dignissim sodales ut eu sem integer vitae justo. Aliquet nec ullamcorper sit amet
        risus nullam eget felis eget. Pretium aenean pharetra magna ac placerat vestibulum lectus
        mauris.
      </p>
      <p className={styles.paragraph}>
        Pharetra magna ac placerat vestibulum lectus mauris ultrices eros. Ut faucibus pulvinar
        elementum integer. Commodo quis imperdiet massa tincidunt nunc. Eget mi proin sed libero
        enim sed. Mi sit amet mauris commodo quis imperdiet massa tincidunt. Massa sapien faucibus
        et molestie ac feugiat sed lectus vestibulum. Amet porttitor eget dolor morbi non arcu risus
        quis. Vitae auctor eu augue ut. Euismod lacinia at quis risus sed. Dui faucibus in ornare
        quam viverra orci. Venenatis a condimentum vitae sapien pellentesque habitant. Tincidunt
        vitae semper quis lectus nulla at volutpat. Consequat interdum varius sit amet mattis.
        Egestas maecenas pharetra convallis posuere morbi leo urna molestie. Libero nunc consequat
        interdum varius sit amet. Pellentesque id nibh tortor id aliquet lectus proin. Adipiscing
        tristique risus nec feugiat in. Facilisis mauris sit amet massa. Vitae tempus quam
        pellentesque nec. Sollicitudin tempor id eu nisl nunc mi ipsum.
      </p>
    </article>
  );
};
