import React, { useState } from "react";
import { ReactComponent as WarningIcon } from "../../icons/warning_icon.svg";
import { ReactComponent as CloseIcon } from "../../icons/x-traced.svg";
import { Link } from "../common/Link";
import { OriginalData } from "./OriginalData";
import styles from "./PhotoDetails.module.scss";
import { PhotoHistory } from "./PhotoHistory";

const TABS = [["original_data", "Photo Overview"], ["photo_history", "History of This Photo"]];

const mobileImgUrl = "/how-it-works/how-it-works-8-26-mobile.pdf";
const desktopImgUrl = "/how-it-works/how-it-works-8-26-desktop.pdf";
const ResponsiveImage = () => {
  return window.screen.width < 1024 ? mobileImgUrl : desktopImgUrl;
};

const TabBarItem = ({ id, text, isSelected, onSelect }) => {
  function handleClick() {
    if (!isSelected && onSelect) {
      onSelect(id);
    }
  }
  let className = styles.tabBar_item;
  if (isSelected) {
    className += " " + styles.tabBar_item__selected;
  }
  return (
    <button className={className} onClick={handleClick}>
      {text}
    </button>
  );
};

export const TabBar = ({ selected, onSelect }) => (
  <div className={styles.tabBar} data-testid="tabBar">
    {TABS.map(([id, text]) => (
      <TabBarItem key={id} id={id} text={text} isSelected={selected === id} onSelect={onSelect} />
    ))}
  </div>
);

const MisinformationSignal = props => {
  return (
    <div className={styles.warning}>
      <div className={styles.warning_innerBorder}>
        <div className={styles.warning_wrap}>
          <WarningIcon className={styles.warning_logoIcon} />
          <div className={styles.warning_text}>
            Did you know? Sometimes photos are shared with inaccurate details.
          </div>
          <CloseIcon
            className={styles.warning_closeIcon}
            onClick={() => props.onClick()}
          ></CloseIcon>
        </div>
        <Link className={`${styles.howItWorks} ${styles.howItWorks_link}`} href={ResponsiveImage()}>
          Learn more about visual misinformation.
        </Link>
      </div>
    </div>
  );
};

/**
 * Displays the 'Original Data' and 'Photo History' tabs when the `VerificationWidget` is expanded
 *
 * @param {object} props Component props
 * @param {object} props.source The source of the photo
 * @param {object} props.origin The origin of the photo
 * @param {object} props.originalCaption The original (initial) photo caption
 * @param {array} props.republishedBy List of publishers that also published the photo
 * @param {array} props.publishHistory List of publish history events
 */
export const PhotoDetails = props => {
  const [selectedTab, setSelectedTab] = useState("original_data");
  const [openWarning, setOpenWarning] = useState(true);

  function showPhotoHistory() {
    setSelectedTab("photo_history");
  }

  function renderTab() {
    switch (selectedTab) {
      case "original_data":
        return <OriginalData {...props} onShowPhotoHistory={showPhotoHistory} />;
      case "photo_history":
        return <PhotoHistory {...props} />;
      default:
        return null;
    }
  }

  function handleCloseMisinformationSignal() {
    sessionStorage.setItem("MisinformationClosed", true);
    setOpenWarning(false);
  }

  return (
    <div>
      {openWarning && sessionStorage.getItem("MisinformationClosed") === null ? (
        <MisinformationSignal onClick={() => handleCloseMisinformationSignal()} />
      ) : null}
      <div data-testid="photoDetails">
        <TabBar selected={selectedTab} onSelect={setSelectedTab} />
        {renderTab()}
      </div>
    </div>
  );
};
