import React, { useState } from "react";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { Hoverable } from "../common/Hoverable";
import styles from "./ChangeDescription.module.scss";

const PhotoCaption = ({ className, photoCaption, isHighlighted }) => {
  const classNames = [
    styles.photoCaption,
    isHighlighted ? styles.photoCaption__highlighted : "",
    className || ""
  ];
  return <div className={classNames.join(" ")}>‘{photoCaption}’</div>;
};

const Popup = ({ newEvent, initialEvent }) => (
  <div className={styles.popup} data-testid="changeDescriptionPopup">
    <table className={styles.popup_inner}>
      <tbody>
        <tr>
          <td className={styles.popup_title}>Initial Caption</td>
          <td className={styles.popup_title}>New Caption</td>
        </tr>
        <tr>
          <td>
            <PhotoCaption photoCaption={initialEvent.photoCaption} />
          </td>
          <td>
            <PhotoCaption photoCaption={newEvent.photoCaption} isHighlighted />
          </td>
        </tr>
        <tr>
          <td className={styles.popup_organization}>{initialEvent.organization.name}</td>
          <td className={styles.popup_organization}>{newEvent.organization.name}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

/**
 * Displays the change of a photo caption between two publishing events
 *
 * @param {object} props Component props
 * @param {object} props.newEvent The publishing event with the new photo caption
 * @param {object} props.initialEvent The publishing event with the initial photo caption
 * @param {function} props.onTogglePopup Callback indicating whether the popup is shown or not
 */
export const ChangeDescription = ({ newEvent, initialEvent, onTogglePopup }) => {
  const [showsPopup, setShowsPopup] = useState(false);
  function onHoverChange(isHovered) {
    setShowsPopup(isHovered);
    if (onTogglePopup) {
      onTogglePopup(isHovered);
    }
  }
  return (
    <div className={styles.container} data-testid="changeDescription">
      <div className={styles.pencilIcon + " lineAnchor"}>
        <PencilIcon />
      </div>
      <div className={styles.main}>
        <div>Compare the new caption to the original:</div>
        <Hoverable onChange={onHoverChange}>
          <PhotoCaption
            className={styles.main_photoCaption}
            photoCaption={newEvent.photoCaption}
            isHighlighted
          />
          {showsPopup ? <Popup newEvent={newEvent} initialEvent={initialEvent} /> : null}
        </Hoverable>
      </div>
    </div>
  );
};
