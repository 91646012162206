import React from "react";
import { ReactComponent as LogoIcon } from "../../icons/local_gazette-v2.svg";
import styles from "./LocalGazette.module.scss";

/**
 * Website of the `Local Gazette` news organization
 */
export const LocalGazette = ({ children }) => {
  window.document.title = "Local Gazette";

  return (
    <div data-testid="localGazette">
      <header className={styles.header}>
        <div className={styles.header_content}>
          <LogoIcon />
          <div className={styles.header_logoText}>Local Gazette</div>
        </div>
      </header>
      <div className={styles.main}>
        <div className={styles.articleContainer}>{children}</div>
        <div className={styles.advertisement}>
          <img
            className={styles.advertisement_image}
            src="/ads/LG.png"
            alt=""
            data-testid="advertisementImage"
          />
        </div>
      </div>
    </div>
  );
};
