import React from "react";
import { ReactComponent as BookmarksIcon } from "../../icons/bookmarks.svg";
import { ReactComponent as HamburgerIcon } from "../../icons/hamburger.svg";
import { ReactComponent as HelpIcon } from "../../icons/help.svg";
import { ReactComponent as LogoIcon } from "../../icons/logo.svg";
import { ReactComponent as NotificationsIcon } from "../../icons/notifications.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import styles from "./Header.module.scss";

const MenuItem = ({ title, showBadge, icon: Icon }) => (
  <div className={styles.menu_item}>
    <Icon className={styles.menu_item_icon} />
    <div className={styles.menu_item_title}>
      {title}
      {showBadge ? (
        <span className={styles.menu_item_notificationBadge} data-testid="headerMenuItemBadge" />
      ) : null}
    </div>
  </div>
);

/**
 * Static component displaying the logo and menu
 */
export const Header = () => {
  return (
    <header className={styles.container} data-testid="header">
      <div className={styles.content}>
        <a className={styles.logo} href="/">
          <LogoIcon />
        </a>
        <HamburgerIcon className={styles.hamburger} />
        <menu className={styles.menu}>
          <MenuItem title="Settings" icon={SettingsIcon} />
          <MenuItem title="Notifications" icon={NotificationsIcon} showBadge />
          <MenuItem title="Help" icon={HelpIcon} />
          <MenuItem title="Bookmarks" icon={BookmarksIcon} />
        </menu>
      </div>
    </header>
  );
};
