import React from "react";
import { ReactComponent as CalendarDarkIcon } from "../../icons/calendar_dark_icon.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar_icon.svg";
import { ReactComponent as LinkIcon } from "../../icons/link_icon.svg";
import { ReactComponent as NationalNewsLogoIcon } from "../../icons/national_news-v2.svg";
import { ReactComponent as LocationPinIcon } from "../../icons/pin_icon.svg";
import relativeTimeCalculator from "../common/ElapsedTime";
import { Link } from "../common/Link";
import styles from "./OriginalData.module.scss";
import { RelatedEvents } from "./RelatedEvents";

const OriginalCaption = ({ source, caption, origin }) => {
  let current = new Date();
  let originalSourceDateTime = origin.dateTime.date + " " + origin.dateTime.time;
  originalSourceDateTime = new Date(originalSourceDateTime).getTime();
  const sixMonthBeforeNow = new Date(current).setMonth(current.getMonth() - 6);

  return (
    <div className={`${styles.section} ${styles.initialCaption}`} data-testid="originalData">
      <div className={styles.captionWrap} data-testid="originalDataLogo">
        <NationalNewsLogoIcon className={styles.initialCaption_logo} />
        <div data-testid="caption" className={styles.initialCaption_text}>
          {caption.text}
        </div>
      </div>
      <div className={`${styles.initialCaption_container} ${styles.initialCaption_container_text}`}>
        <div>{source.name}</div>
        {origin.location ? (
          <div>
            <LocationPinIcon className={styles.initialCaption_infoIcon} />
            Taken in {origin.location.name}
          </div>
        ) : null}
        {originalSourceDateTime < sixMonthBeforeNow ? (
          <div
            className={`${styles.initialCaption_text} ${styles.initialCaption_relativeTimeContainer}`}
          >
            <CalendarDarkIcon className={styles.initialCaption_infoDarkIcon} />
            {relativeTimeCalculator(current, new Date(originalSourceDateTime))}
          </div>
        ) : (
          <div className={styles.initialCaption_container_infoText}>
            <CalendarIcon className={styles.initialCaption_infoIcon} />
            {relativeTimeCalculator(current, new Date(originalSourceDateTime))}
          </div>
        )}
      </div>
      {caption.url ? (
        <>
          <Link className={styles.initialCaption_link} href={caption.url} data-testid="link">
            <LinkIcon className={styles.initialCaption_infoIcon} />
            {source.website + "/..."}
          </Link>
        </>
      ) : null}
    </div>
  );
};

const DateTime = ({ time, date, timezone }) => (
  <time title={`${date} ${time} ${timezone}`}>
    <span className={styles.initialCaption_highlightedText}>{date}</span> at {time} ({timezone})
  </time>
);

function Origin({ credit, location, dateTime, source }) {
  return (
    <div className={`${styles.section} ${styles.initialCaption_creditsText}`} data-testid="origin">
      Taken
      {credit || (location && location.name) || dateTime ? (
        <>
          {credit ? (
            <>
              {" "}
              by <span className={styles.initialCaption_highlightedText}>{credit}</span>,{" "}
              Photographer for {source.name},
            </>
          ) : null}
          {location && location.name ? (
            <>
              {" "}
              in <span className={styles.initialCaption_highlightedText}>{location.name}</span>
            </>
          ) : null}
          {dateTime ? (
            <>
              {" "}
              on <DateTime {...dateTime} />
            </>
          ) : null}
        </>
      ) : null}
      <div className={styles.origin_coordinates} data-testid="coordinates">
        {location && location.coordinates ? (
          <>
            <Link
              className={styles.initialCaption_locationLink}
              href={`https://www.google.com/maps/place/${location.coordinates}`}
            >
              <LocationPinIcon className={styles.initialCaption_infoIcon} />
              <span className={styles.initialCaption_locationLink}>View on map</span>
            </Link>
          </>
        ) : null}
      </div>
    </div>
  );
}

/**
 * Displays the original caption, origin and re-publishers of a photo
 *
 * @param {object} props Component props
 * @param {object} props.source The source of the photo
 * @param {object} props.originalCaption The original (initial) photo caption
 * @param {object} props.origin The origin of the photo
 * @param {array} props.republishedBy List of publishers that also published the photo
 * @param {function} props.onShowPhotoHistory Callback indicating to the parent component that the photo history should be shown
 */
export const OriginalData = ({ source, originalCaption, origin, relatedEvents }) => (
  <div className={styles.container} data-testid="originalData">
    {originalCaption ? (
      <OriginalCaption source={source} caption={originalCaption} origin={origin} />
    ) : null}
    {origin ? <Origin {...origin} source={source} /> : null}
    {relatedEvents ? <RelatedEvents relatedEvents={relatedEvents} /> : null}
  </div>
);
