import React, { Component } from "react";
import { Link } from "../common/Link";
import styles from "./Advertisement.module.scss";

/**
 * Static advertisement component
 */
class Advertisement extends Component {
  render() {
    return (
      <div className={styles.container} data-testid="advertisement">
        <div className={styles.header}>
          <div className={styles.header_title}>Sponsored</div>
          <Link>Create Ad</Link>
        </div>
        <div className={styles.sponsorContent}>
          <img
            className={styles.sponsorContent_image}
            src="/ads/ad1.png"
            alt=""
            data-testid="advertisementImage"
          />
          <div className={styles.sponsorContent_title}>In Harlem, a Tiny Restaurant That Hums</div>
          <Link href="http://localgazette.com">localgazette.com</Link>
          <div className={styles.sponsorContent_text}>
            The high cost of doing business in New York takes a toll on many restaurants …
          </div>
        </div>
        <div>
          <img
            className={styles.sponsorContent_image}
            src="/ads/ad2.png"
            alt=""
            data-testid="advertisementImage"
          />
          <div className={styles.sponsorContent_title}>
            Elon Musk’s Company Takes Baby Steps to Wiring Brains to the Internet
          </div>
          <Link href="http://nationalnews.com">nationalnews.com</Link>
          <div className={styles.sponsorContent_text}>
            Researchers hope the Neuralink system, shown here in an artist’s rendering …
          </div>
        </div>
      </div>
    );
  }
}

export default Advertisement;
