function relativeTimeCalculator(current, originDateTime) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const passedTime = current - originDateTime;

  if (passedTime < msPerMinute) {
    if (Math.round(passedTime / 1000) === 0) {
      return "Just now";
    } else {
      return Math.round(passedTime / 1000) === 1
        ? Math.round(passedTime / 1000) + " second ago"
        : Math.round(passedTime / 1000) + " seconds ago";
    }
  } else if (passedTime < msPerHour) {
    return Math.round(passedTime / msPerMinute) === 1
      ? Math.round(passedTime / msPerMinute) + " minute ago"
      : Math.round(passedTime / msPerMinute) + " minutes ago";
  } else if (passedTime < msPerDay) {
    return Math.round(passedTime / msPerHour) === 1
      ? Math.round(passedTime / msPerHour) + " hour ago"
      : Math.round(passedTime / msPerHour) + " hours ago";
  } else if (passedTime < msPerMonth) {
    return Math.round(passedTime / msPerDay) === 1
      ? Math.round(passedTime / msPerDay) + " day ago"
      : Math.round(passedTime / msPerDay) + " days ago";
  } else if (passedTime < msPerYear) {
    return Math.round(passedTime / msPerMonth) === 1
      ? Math.round(passedTime / msPerMonth) + " month old"
      : Math.round(passedTime / msPerMonth) + " months old";
  } else {
    return Math.round(passedTime / msPerYear) === 1
      ? Math.round(passedTime / msPerYear) + " year old"
      : Math.round(passedTime / msPerYear) + " years old";
  }
}

export default relativeTimeCalculator;
