import React from "react";
import { ReactComponent as LogoIcon } from "../../icons/national_news-v2.svg";
import styles from "./NationalNews.module.scss";

/**
 * Website of the `National News` news organization
 */
export const NationalNews = ({ children }) => {
  window.document.title = "National News";

  return (
    <div data-testid="nationalNews">
      <header className={styles.header}>
        <div className={styles.header_content}>
          <LogoIcon />
          <div className={styles.header_logoText}>
            National
            <br />
            News
          </div>
        </div>
      </header>
      <div className={styles.main}>
        {children}
        <div className={styles.advertisement}>
          <img
            className={styles.advertisement_image}
            src="/ads/National_News.png"
            alt=""
            data-testid="advertisementImage"
          />
        </div>
      </div>
    </div>
  );
};
