import React from "react";
import { getArticle } from "../../services/api";
import { Loader } from "../common/Loader";
import { Article } from "./Article";
import { LocalGazette } from "./LocalGazette";
import { NationalNews } from "./NationalNews";

/**
 * Handles the loading of the article and renders it in either the LocalGazette or NationalNews
 */
export const ArticleContainer = props => {
  const { publishRecordId } = props.match.params;

  function loadArticle() {
    return getArticle(publishRecordId);
  }

  return (
    <Loader load={loadArticle} loadingMessage="Loading article...">
      {({ article }) => {
        switch (article.organization.name) {
          case "Local Gazette":
            return (
              <LocalGazette>
                <Article {...article} />
              </LocalGazette>
            );
          case "National News":
            return (
              <NationalNews>
                <Article {...article} />
              </NationalNews>
            );
          default:
            return "Unsupported news organization";
        }
      }}
    </Loader>
  );
};
