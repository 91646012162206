import React from "react";
import styles from "./Context.module.scss";

export const Context = () => {
  return (
    <div className={styles.intro}>
	    <h2>The News Provenance Project: Proof of Concept</h2>
	    <p>The News Provenance Project created this simulated social media network as a research tool to demonstrate what it might look like for publishers to display provenance information on news photos using metadata published to the Hyperledger Fabric blockchain. This site provides an example of how two hypothetical news organizations (“National News" and "Local Gazette") could display their provenance information to help users understand the origins of the photos they see.
	    </p>
    </div>
  );
};
