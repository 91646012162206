import React, { Component } from "react";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import styles from "./SearchBar.module.scss";

/**
 * Static component to display the search box and tags
 */
export class SearchBar extends Component {
  state = {
    tagList: ["#WorldCup", "#Fortnite", "#KetoDiet"],
    searchInput: ""
  };

  handleClick = event => {
    event.preventDefault();
    this.setState({ searchInput: event.target.value });
  };

  onSearchTextBoxClick = () => {
    this.setState({ searchInput: "" });
  };

  onSearchTextBoxChange = event => {
    this.setState({ searchInput: event.target.value });
  };

  render() {
    return (
      <div className={styles.container} data-testid="searchBar">
        <div className={styles.content}>
          <form className={styles.searchForm}>
            <input
              className={styles.searchForm_textBox}
              type="text"
              placeholder="Search"
              value={this.state.searchInput}
              onChange={this.onSearchTextBoxChange}
              onClick={this.onSearchTextBoxClick}
            />
            <SearchIcon className={styles.searchForm_icon} />
          </form>
          <div className={styles.tags}>
            {this.state.tagList.map(item => (
              <button
                className={styles.buttonTag}
                key={item}
                value={item}
                onClick={this.handleClick}
              >
                {item}
              </button>
            ))}
            <a className={styles.showMoreTags} href="#moreTags">
              Show more tags...
            </a>
          </div>
        </div>
      </div>
    );
  }
}
