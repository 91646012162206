import React from "react";
import Advertisement from "./Advertisement";
import { Header } from "./Header";
import { Newsfeed } from "./Newsfeed";
import { SearchBar } from "./SearchBar";
import styles from "./SocialPlatform.module.scss";

/**
 * Encapsulates the whole social platform UI,
 * including Header, SearchBar, Newsfeed and Advertisement components
 */
export const SocialPlatform = () => {
  window.document.title = "Social Media Platform";

  return (
    <div data-testid="socialPlatform">
      <Header />
      <SearchBar />
      <div className={styles.main}>
        <Newsfeed />
        <Advertisement />
      </div>
    </div>
  );
};
