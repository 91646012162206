import axios from "axios";

export const getNewsfeed = () => {
  return axios.get("/api/newsfeed", { timeout: 15000 }).then(response => response.data);
};

export const getAuthors = () => {
  return axios.get("/api/authors", { timeout: 15000 }).then(response => response.data);
};

export const getArticle = publishRecordId => {
  return axios
    .get("/api/article/" + publishRecordId, { timeout: 15000 })
    .then(response => response.data);
};
