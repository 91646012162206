import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { ArticleContainer } from "./news-sites/ArticleContainer";
import { SocialPlatform } from "./social-platform/SocialPlatform";
import { Context } from "./social-platform/Context";

/**
 * Entry-point of the app. Routes between `SocialPlatform`, `ArticleContainer`
 *
 * - Index route (`/`) goes to the Social Platform UI
 * - Article route (`/articles/:publishId`) goes to the News Sites UI
 */
export const App = () => {
  useEffect(() => {
    window.sessionStorage.clear();
  });

  return (
    <>
      <Context />
      <Route path="/" exact component={SocialPlatform} />
      <Route path="/articles/:publishRecordId" exact component={ArticleContainer} />
    </>
  );
};
